<template>
  <v-card class="pa-8" width="100%">
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <h1 style="font-weight: 800; color: #e53935">ข้อมูลผู้ใช้งาน</h1>
      <v-row>
        <v-col cols="2"><p style="font-weight: 800">username</p></v-col>
        <v-col cols="4">
          <v-text-field
            v-model="data.username"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2"><p style="font-weight: 800">password</p></v-col>
        <v-col cols="4">
          <v-text-field
            v-model="data.password"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2"><p style="font-weight: 800">ส่วนการใช้งาน</p></v-col>
        <v-col cols="4">
          <v-text-field
            v-model="data.userType"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        <v-col cols="2"
          ><p style="font-weight: 800">รายการทะเบียนหนังสือ</p></v-col
        >
        <v-col cols="4">
          <v-select
            v-model="data.bookType"
            :items="bookTypeItems"
            outlined
            dense
            :rules="rules.required"
          ></v-select>
        </v-col>
        <v-col cols="2"><p style="font-weight: 800">ชื่อ</p></v-col>
        <v-col cols="4">
          <v-text-field
            v-model="data.firstName"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        <v-col cols="2"><p style="font-weight: 800">นามสกุล</p></v-col>
        <v-col cols="4">
          <v-text-field
            v-model="data.lastName"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        <v-col cols="2"><p style="font-weight: 800">กองที่สังกัด</p></v-col>
        <v-col cols="10">
          <v-select
            v-model="data.userGroup"
            :items="userGroups"
            outlined
            dense
            :rules="rules.required"
          ></v-select>
        </v-col>
        <v-col cols="2"
          ><p style="font-weight: 800" class="pt-5">สิทธิการอ่าน</p></v-col
        >
        <v-col cols="10">
          <v-switch v-model="data.isView" label="อ่าน"></v-switch>
        </v-col>

        <v-col cols="2"
          ><p style="font-weight: 800" class="pt-5">สิทธิการแก้ไข</p></v-col
        >
        <v-col cols="10">
          <v-switch v-model="data.isEdit" label="แก้ไข"></v-switch>
        </v-col>
        <v-col cols="2"
          ><p style="font-weight: 800" class="pt-5">ใช้งาน</p></v-col
        >
        <v-col cols="10">
          <v-switch v-model="data.activeFlag" label="ใช้งาน"></v-switch>
        </v-col>

        <!-- <v-col cols="2"><p style="font-weight:800">idCargImg</p></v-col>
      <v-col cols="4">
        idCargImg
      </v-col>
      <v-col cols="2"><p style="font-weight:800">idCargImg2</p></v-col>
      <v-col cols="4">
        idCargImg2
      </v-col>
      <v-col cols="2"><p style="font-weight:800">activeFlag</p></v-col>
      <v-col cols="4">
        activeFlag
      </v-col> -->
      </v-row>
      <v-row>
        <v-btn @click="cancel()">กลับ</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="submit()">บันทึก</v-btn>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import { Decode, Endcode } from "@/services";
export default {
  data() {
    return {
      data: {
        username: "",
        password: "",
        firstName: "",
        lastName: "",
        userType: "",
        isView: true,
        isEdit: true,
        bookType: "",
        activeFlag: true,
        userGroup: ""
      },
      userGroups: [
        { text: "สำนักปลัด", value: "สำนักปลัด" },
        { text: "กองคลัง", value: "กองคลัง" },
        { text: "กองช่าง", value: "กองช่าง" },
        { text: "กองการศึกษา", value: "กองการศึกษา" },
        // { text: "กองธุรการ", value: "กองธุรการ" },
      ],
      bookTypeItems: [
        { text: "ทะเบียนหนังสือรับ", value: "menu0" },
        { text: "ทะเบียนหนังสือส่ง", value: "menu1" },
        { text: "ทะเบียนประกาศ", value: "menu2" },
        { text: "ทะเบียนคำสั่ง", value: "menu3" },
        { text: "ทะเบียนบันทึกข้อความ", value: "menu4" },
        { text: "บัญชีหนังสือส่งเก็บ", value: "menu5" },
        { text: "ทะเบียนหนังสือเก็บ", value: "menu6" },
        { text: "บัญชีส่งมอบหนังสือครบ ๒๐ ปี", value: "menu7" },
        { text: "บัญชีหนังสือครบ ๒๐ ปี ที่ขอเก็บเอง", value: "menu8" },
        { text: "บัญชีฝากหนังสือ", value: "menu9" },
        { text: "บัญชีหนังสือขอทำลาย", value: "menu10" },
      ],
      checkValidate: true,
      lazy: false,
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
    };
  },
  created() {
    // this.year = JSON.parse(Decode.decode(localStorage.getItem("yearSelected")));
    var userdata = JSON.parse(Decode.decode(localStorage.getItem("userdata")));
  },
  methods: {
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("usersarabantrapiangtia"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };

        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/users/createAdmin`,
          this.data,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `บันทึกข้อมูลสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageUser");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    cancel() {
      this.$router.push("ManageUser");
    },
  },
};
</script>
